<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <BaseTitleBar
          pageTitle="Approvals"
          :itemsText="user.email ? `for ${user.email}` : ``"
        >
        </BaseTitleBar>
      </v-col>
    </v-row>

    <v-row class="px-2 px-md-0">
      <v-col cols="12">
        <v-card
          flat
          elevation="6"
          max-width="100%"
          min-width="320px"
          min-height=""
          class="mx-auto mx-md-14 mb-10 pb-10"
        >
          <div class="mt-md-12 mx-0">
            <v-card
              flat
              max-width="100%"
              style="border-bottom: 1px solid rgba(127, 145, 155, 0.3)"
            >
              <v-tabs
                align-with-title
                slider-size="4"
                v-model="tab"
                mobile-breakpoint="5"
                v-if="$vuetify.breakpoint.mdAndUp"
              >
                <v-tab
                  class="mt-2"
                  v-for="item in items"
                  :key="item.tab"
                  style="
                    font-family: Inter;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    text-transform: uppercase;
                  "
                >
                  <v-icon
                    v-if="item.tab == 'Exception'"
                    left
                    color="#ff6a6a"
                    small
                    class="pr-1 mr-0"
                  >
                    mdi-stop-circle-outline
                  </v-icon>
                  <span
                    class="font-weight-bold"
                    :style="{
                      color: `${item.tab == 'Exception' ? '#ff6a6a' : ''}`,
                    }"
                    >{{ item.tab }}</span
                  >
                </v-tab>

                <v-spacer></v-spacer>
                <v-btn
                  :loading="isRefreshing"
                  class="mt-4"
                  color="primary"
                  small
                  text
                  @click="sendAction('refresh')"
                  >Refresh <v-icon right>mdi-refresh</v-icon></v-btn
                >

                <v-btn
                  v-if="isClicked"
                  @click="toggleSearch"
                  plain
                  class="text-black mt-1 pt-4 search__btn"
                >
                  search
                  <v-icon small right class="pr-1"> mdi-magnify </v-icon>
                </v-btn>
                <v-expand-x-transition v-else>
                  <v-text-field
                    v-model="search"
                    @blur="isClicked = true && !search"
                    class="seacrh-field mt-2 mr-2"
                    dense
                    clearable
                    autofocus
                    hide-details="true"
                    persistent-placeholder
                    placeholder="Search"
                    append-icon="mdi-magnify"
                    filled
                  >
                  </v-text-field>
                </v-expand-x-transition>
              </v-tabs>
            </v-card>
          </div>
          <component
            v-bind:is="items[tab].content"
            :search-query="search"
            :action="actionMethod"
            :email="user.email"
            @refreshed="
              isRefreshing = false;
              actionMethod = '';
            "
            class="ml-0"
          ></component>
        </v-card>
      </v-col>
    </v-row>

    <!-- tabs for mobile devices -->
    <v-container
      class="pa-0"
      style="margin-top: 10vh"
      v-if="$vuetify.breakpoint.mdAndDown"
    >
      <v-row>
        <v-col cols="12">
          <v-bottom-navigation fixed class="pa-0" dark>
            <v-tabs
              centered
              class="ma-0"
              background-color="primary"
              v-model="tab"
            >
              <v-tab
                class="mt-2"
                v-for="item in items"
                :key="item.tab"
                style="
                  font-family: Inter;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 12px;
                  line-height: 15px;
                  text-transform: uppercase;
                "
                >{{ item.tab }}</v-tab
              >
            </v-tabs>
          </v-bottom-navigation>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import PendingApprovals from "../../components/approval/pendingApprovals.vue";
import ApprovedApprovals from "../../components/approval/approvedApprovals.vue";
import TabDataTableCustomers from "../../components/TabDataTableCustomers.vue";
import NoInvoice from "../../components/noInvoice.vue";
import BaseTitleBar from "../../components/BaseTitleBar.vue";

export default {
  name: "Inbox",
  data() {
    return {
      isClicked: true,
      tab: 0,
      search: "",
      actionMethod: "",
      isRefreshing: false,
      items: [
        { tab: "Pending", content: "PendingApprovals" },
        { tab: "Approved", content: "ApprovedApprovals" },
      ],
    };
  },
  components: {
    PendingApprovals,
    ApprovedApprovals,
    TabDataTableCustomers,
    NoInvoice,
    BaseTitleBar,
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    toggleSearch() {
      this.isClicked = false;
    },

    sendAction(action) {
      this.actionMethod = action;

      if (action == "refresh") {
        this.isRefreshing = true;
      }
    },
  },
};
</script>

<style scoped>
.h-card-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 35, 56, 0.5);
}

.page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
}

.h-card-body-bold {
  max-width: 16rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  /* letter-spacing: 0.545455px; */
  color: #19283d;
}

.h-card-body {
  max-width: 274px;
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 35, 56, 0.5);
}

.v-input .search-field .v-input__slot:before,
.v-input .search-field .v-input__slot:after {
  border: none !important;
  border-color: transparent !important;
}

.transTotal {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #7f919b;
}

.v-menu__content {
  box-shadow: none;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.invite__btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
}

.search__btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
  color: #7f919b;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

th {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: var(--v-primary-base);
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.v-application .elevation-3 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}

.sub__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.45px;
  color: #7f919b;
}
</style>
