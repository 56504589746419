<template>
  <div v-if="approval">
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-hover v-slot:default="{ hover }">
        <v-card
          @click="showApprovalDetails = true"
          class="px-7 py-6"
          style="cursor: pointer; transition: all 0.3s ease"
          :elevation="hover ? 2 : 0"
          :color="index % 2 ? '#f6f6f6' : ''"
        >
          <v-layout row wrap class="align-center">
            <v-flex md1>
              <div class="d-flex align-center pl-10">
                <span class="mb-0 text-center row__text" style="">{{
                  index
                }}</span>
              </div>
            </v-flex>

            <v-flex md1>
              <div class="d-flex align-center pl-4">
                <span class="mb-0 text-center row__text" style="">{{
                  approval.source || "N/A"
                }}</span>
              </div>
            </v-flex>

            <v-flex md3>
              <div class="d-flex align-center">
                <span class="mb-0 text-center row__text" style="">{{
                  approval.requester || "N/A"
                }}</span>
              </div>
            </v-flex>

            <v-flex md3>
              <div class="d-flex data-items align-center">
                <span
                  class="mb-0 text-center row__text"
                  style=""
                  v-for="(item, index) in dataItems"
                  :key="index"
                  >{{ item.key }}</span
                >
              </div>
            </v-flex>
            <v-flex md2>
              <div class="d-flex data-items align-center actions">
                <template v-if="actionTimeline.length > 0">
                  <span
                    v-for="(action, index) in actionTimeline"
                    :key="index"
                    class="actions"
                    :class="{
                      approved: action === true,
                      denied: action === false,
                    }"
                  >
                  </span>
                </template>
                <span v-else class="no-data">NEW</span>
              </div>
            </v-flex>
            <v-flex md1>
              <div class="d-flex align-center">
                <span class="mb-0 text-center row__text" style="">{{
                  approval.is_approved
                    ? approval.date_approved
                    : approval.created_at | date
                }}</span>
              </div>
            </v-flex>

            <v-flex md1>
              <div class="d-flex align-center pl-6">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    dark
                    outlined
                    depressed
                    small
                    color="primary"
                    :style="{
                      'background-color': hover ? '#c4c9cf' : '',
                      border: hover ? 'none' : '',
                      color: hover ? 'white' : '#ffffff',
                    }"
                    class="text-capitalize px-1 my-1 rounded"
                    >...</v-btn
                  >
                </v-hover>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-hover>
    </div>

    <v-hover v-slot:default="{ hover }" v-if="$vuetify.breakpoint.smAndDown">
      <v-card
        class="px-3 py-3"
        style="cursor: pointer; transition: all 0.3s ease"
        :elevation="hover ? 2 : 0"
        :color="index % 2 ? '#f6f6f6' : ''"
        @click="showApprovalDetails = true"
      >
        <div class="d-flex align-center">
          <v-chip
            :color="chipcolor"
            :text-color="statusIcon"
            class="chip__text"
            x-small
            >{{ approval.type || "N/A" }}</v-chip
          >
          <v-spacer></v-spacer>
          <v-hover v-slot="{ hover }">
            <v-btn
              dark
              outlined
              depressed
              small
              color="primary"
              :style="{
                'background-color': hover ? '#c4c9cf' : '',
                border: hover ? 'none' : '',
                color: hover ? 'white' : '#ffffff',
              }"
              class="text-capitalize px-2 my-1 rounded"
              >...</v-btn
            >
          </v-hover>
        </div>
        <div class="d-flex flex-column pl-4 mb-3">
          <span class="mb-1 primary--text font-weight-bold row__text" style=""
            >Source</span
          >
          <span class="row__text" style="">{{ approval.source || "N/A" }}</span>
        </div>
        <div class="d-flex flex-column pl-4 mb-3">
          <span class="mb-1 primary--text font-weight-bold row__text" style=""
            >Requester</span
          >
          <span class="row__text" style="">{{
            approval.requester || "N/A"
          }}</span>
        </div>
        <div class="d-flex flex-column pl-4 mb-3">
          <span class="mb-1 primary--text font-weight-bold row__text" style=""
            >Data Items</span
          >
          <div class="d-flex data-items align-center">
            <span
              class="mb-0 text-center row__text"
              style=""
              v-for="(item, index) in dataItems"
              :key="index"
            ></span>
          </div>
        </div>
      </v-card>
    </v-hover>

    <ApprovalDetails
      @refresh="$emit('refresh')"
      v-model="showApprovalDetails"
      :approval="approval"
      :items="lineItems"
      :email="email"
    />
  </div>
</template>

<script>
import ApprovalDetails from "./approvalDetails.vue";
export default {
  name: "paymentDataTableRow",
  components: {
    ApprovalDetails,
  },
  props: {
    status: {
      type: String,
      default: "pending",
    },
    index: {
      type: Number,
      default: -1,
    },

    approval: {
      type: Object,
      default: () => null,
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showApprovalDetails: false,
    };
  },
  methods: {},
  computed: {
    statusIcon() {
      if (this.approval && this.approval.type) {
        if (this.approval.type === "data") {
          return "#E3AA1C";
        } else if (this.approval.type === "payment") {
          return "#2BD5AE";
        } else return "grey";
      } else return "#000";
    },
    chipcolor() {
      if (this.directiontag === "credit") {
        return "#EEFCF9";
      } else return "#FDF9EF";
    },

    lineItems() {
      if (this.approval && this.approval.items) {
        return Object.keys(this.approval.items).map((key) => {
          return {
            key,
            value: this.approval.items[key],
          };
        });
      } else return [];
    },

    dataItems() {
      if (this.approval && this.approval.items) {
        return Object.keys(this.approval.items)
          .map((key) => {
            return {
              key,
              value: this.approval.items[key],
            };
          })
          .slice(0, 3);
      } else return [];
    },

    actionTimeline() {
      if (this.approval && this.approval.actions) {
        // also take the last 5 actions
        return this.approval.actions
          .map((approval) => approval.is_approved)
          .slice(0, 5);
      } else return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.row__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #596a73;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chip__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
}

.v-application .elevation-2 {
  box-shadow: -6px 3px 1px -2px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 7%) !important;
}

.actions {
  text-overflow: ellipsis;
  color: #596a73;
  display: flex;
  gap: 0px !important;

  span {
    height: 12px;
    width: 10px;
    border-radius: 50%;

    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin-right: 5px !important;
    }

    &.approved {
      background: #2bd5ae;
      color: white;
    }
    &.denied {
      background: #ff5c5c;
      color: white;
    }

    &.no-data {
      height: unset;
      width: unset;
      font-weight: 500;
      font-size: 10px;
      padding: 2px 10px;
      border-radius: 10px;

      color: #16be98;
      background-color: #96ead733;
    }
  }
}

.data-items {
  gap: 8px;

  span {
    background: #d9dee1;
    border-radius: 10px;
    padding: 2px 6px;
    color: rgba(25, 40, 61, 0.8);
    font-weight: 600;
  }
}
</style>
