<template>
  <div>
    <v-skeleton-loader width="100%" type="table" :loading="loadingApprovals">
      <v-container class="pa-0">
        <div v-if="!noData && !refreshing && !loadingApprovals">
          <v-card
            v-if="$vuetify.breakpoint.mdAndUp"
            width="100%"
            flat
            class="d-flex align-center py-2"
            style="background: rgba(127, 145, 155, 0.052607)"
          >
            <div class="d-flex align-center" style="width: 230px">
              <v-select
                v-model="selectedDateTime"
                :items="dateTimeFilter"
                placeholder="Date Filter"
                outlined
                background-color="#ffffff"
                hide-details="auto"
                dense
                class="pl-10"
              >
              </v-select>
            </div>
            <v-spacer></v-spacer>
            <download-csv
              class="btn btn-default"
              :data="filteredApprovals"
              v-if="filteredApprovals && filteredApprovals.length > 0"
            >
              <v-hover v-slot="{ hover }">
                <v-btn
                  outlined
                  @click="alertCSVDownload"
                  color="primary"
                  class="my-4 export-btn mr-9 hover-btn"
                  :style="{
                    'background-color': hover ? '#2bd5ae' : '',
                    border: hover ? 'none' : '',
                  }"
                  elevation="2"
                  ><span class="material-icons pr-1"> import_export </span
                  ><span class="primary--text export-btn">Export</span>
                </v-btn>
              </v-hover>
            </download-csv>
          </v-card>
        </div>

        <!-- inbox tabs for desktop screens -->
        <v-skeleton-loader
          type="table"
          :loading="isLoading"
          v-if="!noData && !refreshing && !loadingApprovals"
        >
          <v-layout
            row
            wrap
            class="align-center py-8 px-8 text-body-2"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-flex md1>
              <div class="pl-6">
                <p class="mb-0 primary--text font-weight-bold">S/N</p>
              </div>
            </v-flex>
            <v-flex md1>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold text-center">
                  Type
                </p>
              </div>
            </v-flex>

            <v-flex md3>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold">Requester</p>
              </div>
            </v-flex>
            <v-flex md3>
              <div>
                <p class="mb-0 pl-md-1 primary--text font-weight-bold">
                  Data Items
                </p>
              </div>
            </v-flex>
            <v-flex md2>
              <div>
                <p class="mb-0 primary--text font-weight-bold">Actions</p>
              </div>
            </v-flex>
            <v-flex md1>
              <div class="d-flex align-center">
                <p class="mb-0 primary--text font-weight-bold">Date</p>
              </div>
            </v-flex>
            <v-flex md1>
              <div class="d-flex align-center">
                <p class="mb-0 pl-md-8 primary--text font-weight-bold">
                  Action
                </p>
              </div>
            </v-flex>
          </v-layout>
          <v-row v-if="allApprovals && allApprovals.length > 0" class="my-0">
            <v-col
              cols="12"
              v-for="invoice in displayedItems"
              :key="invoice.index"
              class="py-0 ma-md-0"
            >
              <approval-table-row
                @refresh="getAllApprovals"
                :index="invoice.index"
                :approval="invoice"
                :email="email"
              />
            </v-col>
          </v-row>

          <div
            class="pagination__container px-8"
            v-if="filteredApprovals && filteredApprovals.length > 0"
          >
            <BasePagination
              :current-page="currentPage"
              :visible-pages="visiblePageValue"
              :total-pages="totalPages"
              @page-changed="handlePageChange"
            />
          </div>
        </v-skeleton-loader>
      </v-container>
    </v-skeleton-loader>
    <div id="noInvoice" v-if="noData && !refreshing && !loadingApprovals">
      <!-- instructions when there is no invoice -->

      <no-invoice title="No Pending Approvals at the moment" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import pagination from "@/mixins/pagination";
import NoInvoice from "@/components/BaseEmptyState.vue";

import BasePagination from "@/components/BasePagination.vue";
import ApprovalTableRow from "./tableRow.vue";
export default {
  components: {
    BasePagination,
    ApprovalTableRow,
    NoInvoice,
  },
  mixins: [pagination],
  props: {
    action: {
      type: String,
      default: "",
    },
    searchQuery: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      refreshing: false,
      noData: false,
      loadingApprovals: false,
      selectedDateTime: 1,
      dateTimeFilter: [
        { text: "All time", value: 0 },
        { text: "Last 24 Hours", value: 1 },
        { text: "Last 7 Days", value: 2 },
        { text: "Last 30 Days", value: 3 },
      ],
      isApproved: null,
      isLoading: false,
      itemsPerPage: 10,
    };
  },
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async getAllApprovals() {
      try {
        this.loadingApprovals = true;
        await this.$store.dispatch("approvals/getAllApprovals", this.orgId);
      } finally {
        this.loadingApprovals = false;
      }
    },

    alertCSVDownload() {
      this.showToast({
        sclass: "success",
        show: true,
        message: "CSV downloaded!",
        timeout: 3000,
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      allApprovals: "approvals/getAllApprovals",
      pagination: "approvals/getPagination",
    }),

    filteredApprovals() {
      const appv = this.allApprovals
        ? this.allApprovals.filter(
            (aap) => aap.is_approved === null || aap.is_approved === false
          )
        : [];

      // type
      // source
      // requester
      // items
      // title

      if (this.searchQuery) {
        console.log(this.searchQuery);

        return this.appv?.filter((approval) => {
          return (
            this.searchQuery.toLowercase().indexOf(approval.title) > -1 ||
            this.searchQuery.toLowercase().indexOf(approval.type) > -1 ||
            this.searchQuery.toLowercase().indexOf(approval.source) > -1 ||
            this.searchQuery.toLowercase().indexOf(approval.requester) > -1 ||
            this.searchQuery.toLowercase().indexOf(approval.items) > -1
          );
        });
      } else return appv;
    },
    // get the length of the filtered form array to use it as the value of the visible page
    visiblePageValue() {
      const pageNumber = Math.floor(
        this.filteredApprovals?.length / this.itemsPerPage
      );
      return pageNumber > 5 ? 5 : pageNumber < 1 ? 1 : pageNumber;
    },

    // get array per page
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      if (
        this.filteredApprovals &&
        this.filteredApprovals.length > this.itemsPerPage
      ) {
        let arraySubset = this.filteredApprovals?.slice(startIndex, endIndex);
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      } else {
        let arraySubset = this.filteredApprovals?.slice();
        return arraySubset?.map((item, index) => {
          if (this.currentPage === 1) {
            return {
              index: index + 1,
              ...item,
            };
          } else {
            return {
              index: index + 1 + (this.currentPage - 1) * 10,
              ...item,
            };
          }
        });
      }
    },

    totalPages() {
      return Math.ceil(this.filteredApprovals.length / this.itemsPerPage);
    },
  },
  async mounted() {},
  watch: {
    orgId: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && this.filteredApprovals.length === 0) {
          this.getAllApprovals();
        }
      },
    },

    filteredApprovals: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length === 0) {
          //console.log(JSON.stringify(val, null, 2));
          this.noData = true;
        } else {
          this.noData = false;
        }
      },
    },

    action: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (val === "refresh") {
          this.refreshing = true;
          await this.getAllApprovals();
          this.refreshing = false;
          this.$emit("refreshed");
        }
      },
    },
  },
};
</script>

<style scoped>
.v-input__slot {
  margin-bottom: 0px;
}

.switch-card {
  height: 53px;
  background: rgba(127, 145, 155, 0.052607);
}

.switch {
  width: 15.28px;
  height: 15.28px;
}

hover-btn:hover {
  background-color: lightseagreen;
  color: midnightblue;
}

.export-btn {
  font-family: "Inter" sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;

  color: #19283d;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pagination__container {
  display: flex;
  justify-content: right;
  align-items: center;
  /* margin-top: 20px;
    margin-bottom: 0px;
    padding-top: 30px;
    padding-right: -20px; */
}

tbody tr:nth-of-type(odd) {
  background-color: #f8f9fc;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

table td,
table th {
  height: 40px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 36px;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.export-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.636364px;
}
</style>
