var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.approval)?_c('div',[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"px-7 py-6",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''},on:{"click":function($event){_vm.showApprovalDetails = true}}},[_c('v-layout',{staticClass:"align-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center pl-10"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm.index))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center pl-4"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm.approval.source || "N/A"))])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm.approval.requester || "N/A"))])])]),_c('v-flex',{attrs:{"md3":""}},[_c('div',{staticClass:"d-flex data-items align-center"},_vm._l((_vm.dataItems),function(item,index){return _c('span',{key:index,staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(item.key))])}),0)]),_c('v-flex',{attrs:{"md2":""}},[_c('div',{staticClass:"d-flex data-items align-center actions"},[(_vm.actionTimeline.length > 0)?_vm._l((_vm.actionTimeline),function(action,index){return _c('span',{key:index,staticClass:"actions",class:{
                    approved: action === true,
                    denied: action === false,
                  }})}):_c('span',{staticClass:"no-data"},[_vm._v("NEW")])],2)]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 text-center row__text"},[_vm._v(_vm._s(_vm._f("date")(_vm.approval.is_approved ? _vm.approval.date_approved : _vm.approval.created_at)))])])]),_c('v-flex',{attrs:{"md1":""}},[_c('div',{staticClass:"d-flex align-center pl-6"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-1 my-1 rounded",style:({
                    'background-color': hover ? '#c4c9cf' : '',
                    border: hover ? 'none' : '',
                    color: hover ? 'white' : '#ffffff',
                  }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"}},[_vm._v("...")])]}}],null,true)})],1)])],1)],1)]}}],null,false,402301437)})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-card',{staticClass:"px-3 py-3",staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},attrs:{"elevation":hover ? 2 : 0,"color":_vm.index % 2 ? '#f6f6f6' : ''},on:{"click":function($event){_vm.showApprovalDetails = true}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"chip__text",attrs:{"color":_vm.chipcolor,"text-color":_vm.statusIcon,"x-small":""}},[_vm._v(_vm._s(_vm.approval.type || "N/A"))]),_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"text-capitalize px-2 my-1 rounded",style:({
              'background-color': hover ? '#c4c9cf' : '',
              border: hover ? 'none' : '',
              color: hover ? 'white' : '#ffffff',
            }),attrs:{"dark":"","outlined":"","depressed":"","small":"","color":"primary"}},[_vm._v("...")])]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-column pl-4 mb-3"},[_c('span',{staticClass:"mb-1 primary--text font-weight-bold row__text"},[_vm._v("Source")]),_c('span',{staticClass:"row__text"},[_vm._v(_vm._s(_vm.approval.source || "N/A"))])]),_c('div',{staticClass:"d-flex flex-column pl-4 mb-3"},[_c('span',{staticClass:"mb-1 primary--text font-weight-bold row__text"},[_vm._v("Requester")]),_c('span',{staticClass:"row__text"},[_vm._v(_vm._s(_vm.approval.requester || "N/A"))])]),_c('div',{staticClass:"d-flex flex-column pl-4 mb-3"},[_c('span',{staticClass:"mb-1 primary--text font-weight-bold row__text"},[_vm._v("Data Items")]),_c('div',{staticClass:"d-flex data-items align-center"},_vm._l((_vm.dataItems),function(item,index){return _c('span',{key:index,staticClass:"mb-0 text-center row__text"})}),0)])])]}}],null,false,204814644)}):_vm._e(),_c('ApprovalDetails',{attrs:{"approval":_vm.approval,"items":_vm.lineItems,"email":_vm.email},on:{"refresh":function($event){return _vm.$emit('refresh')}},model:{value:(_vm.showApprovalDetails),callback:function ($$v) {_vm.showApprovalDetails=$$v},expression:"showApprovalDetails"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }